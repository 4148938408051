export class RegistrazioneStudenteRequest {
  nome?: string;
  cognome?: string;
  email?: string;
  password?: string;
  dataNascita?: string;
  // valutazioneMaterie: Array<ValutazioneMateria>;
  photoUrl?: string;
  token?: string;
  classeId?: string;
  sesso?: boolean;

  constructor() {
    // this.valutazioneMaterie = [];
  }
}

export interface ValutazioneMateria {
  materiaId: string;
  valore: number;
}
