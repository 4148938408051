import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_service';
import { Constants } from '../Constants';
import { MyToastrService } from '..';
// import { AuthenticationService } from '../authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: MyToastrService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      
      const error = err.error?.message || err.error?.status;
      if (request.url.includes('/auth')) {
        return throwError(() => error);
      }
      
      this.toastr.ERROR(Constants.TITLE_ERROR, error);

      // switch (err.status) {
      //   case 401:
      //     this.authService.logout();
      //     this.router.navigate(Constants.Routing.ACCESS_DENIED.routerLink);
      //     break;
      //   case 404:
      //     this.router.navigate(Constants.Routing.NOT_FOUND.routerLink);
      //     break;
      //   case 500:
      //     this.router.navigate(Constants.Routing.ERROR500.routerLink);
      //     break;
      //   default:
      //     this.toastr.ERROR(Constants.TITLE_ERROR, error);
      //     break;
      // }

      return throwError(() => error);
    }));
  }
}
