import { TipologiaDomandaModulo } from ".";

export class DomandeModuli {
  id?: string;
  testo?: string;
  tracce?: Tracce[];
  gradi?: number[];
  durata?: number;
  nome?: string;
}

export class Tracce {
 elementi?: Elementi[];
 testo?: string;
}

export class Elementi {
  id?: string;
  testo?: string;
  tipologia?: TipologiaDomandaModulo;
  domande?: Elementi[];
  risposte?: Risposte[];
  risposta?: any;
}

export class Risposte {
  testo?: string;
  valore?: number;
}
