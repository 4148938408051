import { ModuloValutazioneDoc, ModuloValutazioneRisposta } from ".";

export class ModuloValutazioneDocente {
  id?: string;
  moduloValutazioneDoc?: ModuloValutazioneDoc;
  canEvaluate?: boolean;
  competenze?: Competenza[];
}

export class Competenza {
  id?: string;
  testo?: string;
  risposte?: ModuloValutazioneRisposta[];
  risposta?: ModuloValutazioneRisposta;
  optional?: boolean;
}