import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IstitutoResponse, PageMateriaResponse, MateriaGruppoResponse, PlessoResponse, TitoloResponse, SpecializzazioneResponse, RegistrazioneDocenteRequest, RichiestaDocenteIscrizione, CompletaRegistrazione, IscrizioneStudenteResponse, RegistrazioneStudente, RegistrazioneStudenteRequest } from '../_model';
import { ComuneResponse } from '../_model/interface/response/comune-response';
import { Constants } from '../_utility';

@Injectable({
    providedIn: 'root'
})
export class RegistrazioneService {


    constructor(
        private http: HttpClient
    ) { }

    getAlreadyRegistered(email: string): Observable<boolean> {
        let url = environment.host + Constants.RegistrazioneService.ALREADY_REGISTERED_URL(email);
        return this.http.get<boolean>(url);
    }

    getMaterie(size?: number): Observable<PageMateriaResponse> {
        let url = environment.host + Constants.RegistrazioneService.MATERIE_URL;
        let queryParams: any = {};
        if (size) {
            queryParams.size = size;
        }
        return this.http.get<PageMateriaResponse>(url, { params: queryParams });
    }

    getMaterieGruppi(): Observable<Array<MateriaGruppoResponse>> {
        let url = environment.host + Constants.RegistrazioneService.MATERIE_GRUPPI_URL;
        return this.http.get<Array<MateriaGruppoResponse>>(url);
    }

    getComuni(nome?: string): Observable<Array<ComuneResponse>> {
        let url = environment.host + Constants.RegistrazioneService.COMUNI_URL;
        let params: any = {};
        if (nome) {
            params.nome = nome;
        }
        return this.http.get<Array<ComuneResponse>>(url, { params: params });
    }

    getIstitutiByIdComune(idComune: string): Observable<Array<IstitutoResponse>> {
        let url = environment.host + Constants.RegistrazioneService.ISTITUTI_URL(idComune);
        return this.http.get<Array<IstitutoResponse>>(url);
    }

    getPlessiByIdIstituto(idIstituto: string, nome?: string): Observable<Array<PlessoResponse>> {
        let url = environment.host + Constants.RegistrazioneService.PLESSI_URL(idIstituto);
        let params: any = {};
        if (nome) {
            params.nome = nome;
        }
        return this.http.get<Array<PlessoResponse>>(url, { params: params });
    }

    getTitoliDiStudio(): Observable<TitoloResponse> {
        let url = environment.host + Constants.RegistrazioneService.TITOLI_URL;
        return this.http.get<TitoloResponse>(url);
    }

    getSpecializzazioni(): Observable<SpecializzazioneResponse> {
        let url = environment.host + Constants.RegistrazioneService.SPECIALIZZAZIONI_URL;
        return this.http.get<SpecializzazioneResponse>(url);
    }

    postRegistrazioneDocente(body: RegistrazioneDocenteRequest) {
        let url = environment.host + Constants.RegistrazioneService.REGISTRAZIONE_DOCENTE_URL;
        return this.http.post(url, body);
    }

    getRichiestaIscrizioneDocente(token: string): Observable<RichiestaDocenteIscrizione> {
        let url = environment.host + Constants.RegistrazioneService.RICHIESTA_REGISTRAZIONE_DOCENTE_URL;
        let params: any = {
            token: token
        };
        return this.http.get<RichiestaDocenteIscrizione>(url, { params: params });
    }

    postCompletaRegistrazioneDocente(token: string, body: CompletaRegistrazione) {
        let url = environment.host + Constants.RegistrazioneService.COMPLETA_REGISTRAZIONE_DOCENTE_URL;
        let params: any = {
            token: token
        };
        return this.http.post(url, body, { params: params });
    }

    // Studente

    getClasseByCodice(codice: string): Observable<IscrizioneStudenteResponse> {
        let url = environment.host + Constants.RegistrazioneService.REGISTRAZIONE_STUDENTE_URL;
        let params: any = {
            codice: codice
        };
        return this.http.get<IscrizioneStudenteResponse>(url, { params: params });
    }

    getClasseByToken(token: string): Observable<IscrizioneStudenteResponse> {
        let url = environment.host + Constants.RegistrazioneService.REGISTRAZIONE_STUDENTE_URL;
        let params: any = {
            token: token
        };
        return this.http.get<IscrizioneStudenteResponse>(url, { params: params });
    }

    postCompletaRegistrazioneStudente(body: RegistrazioneStudenteRequest): Observable<any> {
        let url = environment.host + Constants.RegistrazioneService.REGISTRAZIONE_STUDENTE_URL;
        return this.http.post(url, body);
    }
}
