export * from './interface/user';
export * from './interface/authority';
export * from './interface/sort';
export * from './interface/pageable-object';
export * from './interface/response/materia-response';
export * from './interface/response/materia-gruppo-response';
export * from './interface/response/page-materia-response';
export * from './interface/response/istituto-response';
export * from './interface/response/plesso-response';
export * from './interface/response/titoloStudio-response';
export * from './interface/response/specializzazione-response';
export * from './interface/response/docente-richiesta-iscrizione';
export * from './interface/response/iscrizione-studente-response';
export * from './enum/type';
export * from './enum/lang';
export * from './enum/stato';
export * from './enum/StatoRegistrazione';
export * from './RegistrazioneDocente';
export * from './RegistrazioneStudente';
export * from './RegistrazioneDocenteRequest';
export * from './CompletaRegistrazione';
export * from './Classe';
export * from './Studente';
export * from './RegistrazioneStudenteRequest';
