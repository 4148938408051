import { Authority, Classe, Type } from "..";

export interface User {
    //Property firebase
    uid?: string;
    displayName: string;
    emailVerified?: boolean;
    token: string;

    //Custom property
    cognome?: string;
    stato?: any;
    avatarUrl?: any;
    isTeacher?: boolean;
    isAdmin?: boolean;
    nicknameChanged?: any;

    id?: string;
    email?: string;
    firebaseId?: string;
    name?: string;
    username?: string;
    authorities?: Array<Authority>;
}
