import { Stato } from "../../enum/stato";

export interface IstitutoResponse {
    id: string;
    sorgente: string;
    codice: string;
    nome: string;
    tipo: string;
    idComune: string;
    stato: Stato;
    istituto: Istituto;
}

export class Istituto {
    id!: string;
    sorgente?: string;
    codice?: string;
    nome?: string;
    tipo?: string;
}