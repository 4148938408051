export class Classe {
  annoScolastico?: number;
  codiceClasse?: string;
  completamento?: number;
  docenteReferenteId?: string;
  grado?: number;
  id?: string;
  numeroStudenti?: number;
  propostaReferente?: string;
  propostaReferenteCognome?: string;
  propostaReferenteNome?: string;
  referente?: boolean;
  sezione?: string;
}
