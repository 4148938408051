import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Type, User } from '../../_model';
import { Constants, CookieStoragerService, LocalStoragerService, MyToastrService, SessionStoragerService } from '../../_utility';
import { AngularFirestore, AngularFirestoreDocument, } from '@angular/fire/compat/firestore';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public lStorager: LocalStoragerService,
    public sStorager: SessionStoragerService,
    public cStorager: CookieStoragerService,
    private http: HttpClient,
    private helperJwt: JwtHelperService,
    private toastr: MyToastrService
  ) {
  }

  // Ritorna utente salvato nello storage
  get user(): User {
    const element = this.lStorager.getElement(Constants.Auth.USER_KEY);
    const user = element ? element : null;
    return user;
  }

  set user(user: User | null) {
    if (!user) {
      this.lStorager.removeElement(Constants.Auth.USER_KEY);
    } else {
      this.lStorager.setElement(Constants.Auth.USER_KEY, user);
    }
  }

  // Ritorna true quando utente è loggato e l'email è verifcato
  get isLoggedIn(): boolean {
    return this.user !== null && this.user.emailVerified !== false ? true : false;
  }

  // Ritorna il token o stringa vuota
  get token(): string {
    return this.user && this.user.token ? this.user.token : "";
  }

  set remember(flag: boolean) {
    if (flag) {
      this.lStorager.setElement(Constants.Auth.REMEMBER_KEY, true);
    } else {
      this.sStorager.setElement(Constants.Auth.REMEMBER_KEY, true);
    }
  }

  get remember(): boolean {
    return this.lStorager.getElement(Constants.Auth.REMEMBER_KEY) || this.sStorager.getElement(Constants.Auth.REMEMBER_KEY);
  }

  get checkEnvironmentCookie(): boolean {
    let currentEnv = environment.env;
    let cookieEnvironment = this.cStorager.getElement(Constants.Auth.ENV_KEY);
    return currentEnv === cookieEnvironment;
  }

  openToastrError(title: string, errorCode: string) {
    let msg;
    switch (errorCode) {
      case 'auth/email-already-in-use':
        msg = "TOASTR.EMAIL_ALREADY_USE";
        break;
      case 'auth/invalid-email':
        msg = "TOASTR.INVALID_EMAIL";
        break;
      case 'auth/wrong-password':
        msg = "TOASTR.WRONG_PWD";
        break;
      case 'auth/timeout':
        msg = "TOASTR.TIMEOUT";
        break;
      case 'auth/too-many-requests':
        msg = "TOASTR.TOO_MANY_REQUESTS";
        break;
      case 'auth/user-not-found':
        msg = "TOASTR.USER_NOT_FOUND";
        break;
      case 'auth/user-disabled':
        msg = "TOASTR.USER_DISABLED";
        break;
      case 'auth/weak-password':
        msg = "TOASTR.WEAK_PWD";
        break;
      default:
        msg = "TOASTR.GENERIC_ERROR";
        return this.toastr.GENERIC_ERROR(title, msg, errorCode);
    }
    return this.toastr.ERROR(title, msg);
  }

  private async setUserInfo(res: any, createCookie: boolean = true) {
    let user = res.user;
    if (user) {
      user
      let currentUser: User = {
        token: await user.getIdToken()
      } as User;
      this.user = currentUser;
      if (createCookie) {
        await firstValueFrom(this.createCookie());
      }
      let resProf: any = await firstValueFrom(this.profile());

      currentUser.name = resProf.name;
      currentUser.email = resProf.email;
      currentUser.authorities = resProf.authorities;
      currentUser.avatarUrl = resProf.avatarUrl;
      currentUser.isTeacher = currentUser.authorities?.findIndex(authorities => authorities.authority === Type.docente) !== -1;
      currentUser.isAdmin = currentUser.authorities?.findIndex(authorities => authorities.authority === Type.admin) !== -1;

      this.user = currentUser;
    }
  }

  // signIn con email e password
  async authenticate(email: string, password: string, remember: boolean = false) {
    let persistence: "session" | "local" | "none" = "none";
    await this.afAuth.setPersistence(persistence);

    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(async (res) => {
        await this.setUserInfo(res);
        this.remember = remember;
        let domain = environment.futuri.domain;
        this.cStorager.setElement(Constants.Auth.REMEMBER_KEY, { remember: remember ? 'local' : 'session' }, undefined, undefined, '/', domain);
        let env = environment.env;
        this.cStorager.setElement(Constants.Auth.ENV_KEY, env, undefined, undefined, '/', domain);

      })
      .catch((error) => {
        this.user = null;
        if (typeof error === 'string') {//Firebase
          if (error) {
            this.toastr.ERROR("TOASTR.LOGIN", error);
          } else {
            this.toastr.ERROR("TOASTR.LOGIN", "TOASTR.GENERIC_ERROR");
          }
        } else {
          this.openToastrError("TOASTR.LOGIN", error.code);
        }
      });
  }

  async refreshToken(): Promise<boolean> {
    try {
      let currentUser = await this.afAuth.currentUser;
      let newToken = <string>await currentUser?.getIdToken(true);
      let refreshUser = this.user;
      refreshUser.token = newToken;
      this.user = refreshUser;
      return true;
    } catch {
      return false;
    }
  }

  goToLogin(): Observable<boolean> {
    this.router.navigate(Constants.Routing.LOGIN.routerLink);
    return new Observable<boolean>(o => o.next(false));
  }

  async logout(redirect?: boolean) {
    try {
      this.lStorager.clearAll();
      this.sStorager.clearAll();
      await firstValueFrom(this.deleteCookie());
      this.cStorager.clearAll();
      await this.afAuth.signOut();
    } finally {
      this.user = null;
      if (redirect)
        this.goToLogin();
    }
  }

  profile(): Observable<Partial<User>> {
    let url = environment.host + `/users`
    return this.http.get(url);
  }

  /* isExpiredToken(): boolean {
    return this.helperJwt.isTokenExpired(this.token);
  } */

  // Reset password dimenticata
  forgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.toastr.INFO("TOASTR.RESET_PWD", "TOASTR.CHECK_EMAIL")
      })
      .catch((error) => {
        this.openToastrError("TOASTR.RESET_PWD", error.code);
      });
  }

  // Registrazione con email/password
  registration(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.setUserData(result.user);
      })
      .catch((error) => {
        this.openToastrError("TOASTR.REGISTRATION", error.code);
      });
  }

  setUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  signInWithCustomToken(customToken: string) {
    return this.afAuth.signInWithCustomToken(customToken)
      .then(async (res) => {
        await this.setUserInfo(res, false);
        this.cStorager.setElement(Constants.Auth.REMEMBER_KEY, { remember: this.lStorager.getElement(Constants.Auth.REMEMBER_KEY) ? 'local' : 'session' }, undefined, undefined, '/', window.location.hostname);
      });
  }

  createCookie(): Observable<any> {
    let url = environment.host + `/auth/login`
    return this.http.get(url);
  }

  deleteCookie(): Observable<any> {
    let url = environment.host + `/auth/logout`
    return this.http.get(url, { withCredentials: true });
  }

  getCustomTokenFromCookie(): Observable<string> {
    let url = environment.host + `/auth/status`
    return this.http.get(url, { withCredentials: true, responseType: 'text' });
  }
}
