import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';

declare var google: any;
declare var gapi: any;

@Injectable({
  providedIn: 'root',
})
export class ClassroomAuthService {

  private tokenClient!: any;

  /**
   * Callback after api.js is loaded.
   */
  public gapiLoaded() {
    gapi.load('client', this.intializeGapiClient);
  }

  /**
   * Callback after the API client is loaded. Loads the
   * discovery doc to initialize the API.
   */
  public async intializeGapiClient() {
    await gapi.client.init(
      {
        apiKey: env.classroom.API_KEY,
        discoveryDocs: env.classroom.DISCOVERY_DOCS
      });
  }

  /**
   * Callback after Google Identity Services are loaded.
   */
  public gisLoaded() {
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: env.classroom.CLIENT_ID,
      scope: env.classroom.SCOPES,
      callback: ''
    });
  }

  /**
   *  Sign in the user upon button click.
   */
  public async signIn(funct: Function) {
    this.tokenClient.callback = async (resp: any) => {

      if (resp.error !== undefined) {
        throw (resp);
      }
      funct(await this.getProfile());
    };

    this.tokenClient.requestAccessToken({ prompt: '' });
  }

  /**
   *  Sign out the user upon button click.
   */
  public handleSignoutClick() {
    const token = gapi.client.getToken();
    if (token !== null) {
      google.accounts.oauth2.revoke(token.access_token);
      gapi.client.setToken('');
    }
  }

  public async getProfile(): Promise<any> {
    let response = await gapi.client.classroom.userProfiles.get({ userId: 'me' });
    let res: any = response.result;
    return { email: res.emailAddress, nome: res.name?.givenName, cognome: res.name?.familyName };
  }
}