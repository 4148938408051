import { MateriaResponse, PageableObject, Sort } from "../..";

export interface PageMateriaResponse {
    totalElements: number;
    totalPages: number;
    number: number;
    sort: Sort;
    size: number;
    content: Array<MateriaResponse>,
    last: boolean;
    numberOfElements: number;
    pageable: PageableObject,
    first: boolean;
    empty: boolean;
}