import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RegistrazioneDocente } from 'src/app/_model';
import { Constants, SessionStoragerService } from '..';

@Injectable()
export class RegistrazioneDocenteGuard implements CanActivate {

    constructor(
        private router: Router,
        private sStorager: SessionStoragerService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        let numStep = route.data['numStep'];
        let obj: RegistrazioneDocente = this.sStorager.getElement(Constants.Storager.REGISTRAZIONE_DOCENTE);
        let step = obj && obj.step ? obj.step : 1;
        let token = obj && obj.token ? obj.token : route.queryParams['token'];
        if (numStep == 1 && step > 2) {
            this.sStorager.removeElement(Constants.Storager.REGISTRAZIONE_DOCENTE);
            return true;
        }
        if (step > 2 && numStep < 3) {
            let routerLink = obj && obj.routerLink ? obj.routerLink : Constants.Routing.REGISTRAZIONE_DOCENTE_STEP_ONE.routerLink;
            this.router.navigate(routerLink);
            return false;
        } else if (numStep == 1) {
            return true;
        } else if (numStep == 3 && token) {
            return true;
        } else if (numStep > step) {
            let routerLink = obj && obj.routerLink ? obj.routerLink : Constants.Routing.REGISTRAZIONE_DOCENTE_STEP_ONE.routerLink;
            this.router.navigate(routerLink);
            return false;
        } else {
            return true;
        }
    }
}
