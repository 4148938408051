import { StatoStudente } from "./enum/statoStudente";

export class Studente {
  id?: string;
  nome?: string;
  cognome?: string;
  statoInvito?: StatoStudente;
  codiceInvito?: string;
  idInvito?: string;
  email?: string;
  sesso?: boolean;
  dataNascita?: Date;
}
