import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class MyToastrService {

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  INFO(title: string, msg: string) {
    msg = this.translate.instant(msg);
    title = this.translate.instant(title);
    this.toastr.info(msg, title);
  }

  WARNING(title: string, msg: string) {
    msg = this.translate.instant(msg);
    title = this.translate.instant(title);
    this.toastr.warning(msg, title);
  }

  ERROR(title: string, msg: string) {
    msg = this.translate.instant(msg);
    title = this.translate.instant(title);
    this.toastr.error(msg, title);
  }

  GENERIC_ERROR(title: string, msg: string, value?: string) {
    msg = this.translate.instant(msg) 
    if (!!value) {
      msg = this.translate.instant(msg) + ' ' + value;
    }
    title = this.translate.instant(title);
    this.toastr.error(msg, title);
  }

  SUCCESS(title: string, msg: string) {
    msg = this.translate.instant(msg);
    title = this.translate.instant(title);
    this.toastr.success(msg, title);
  }

}
