export class RegistrazioneDocenteRequest {
    nome?: string;
    cognome?: string;
    email?: string;
    plessoNome?: string;
    scuolaId?: string;
    materie?: Array<string>
    note?: string;

    constructor() { }
}