import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RegistrazioneStudente } from 'src/app/_model';
import { Constants, SessionStoragerService } from '..';

@Injectable({
  providedIn: 'root'
})
export class RegistrazioneStudenteGuard implements CanActivate {

  constructor(
    private router: Router,
    private sStorager: SessionStoragerService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    let numStep = route.data['numStep'];
    let obj: RegistrazioneStudente = this.sStorager.getElement(Constants.Storager.REGISTRAZIONE_STUDENTE);
    let step = obj && obj.step ? obj.step : 1;
    if (numStep > step) {
      let routerLink = obj && obj.routerLink ? obj.routerLink : Constants.Routing.REGISTRAZIONE_STUDENTE_STEP_ONE.routerLink;
      this.router.navigate(routerLink);
      return false;
    } else {
      return true;
    }
  }

}
