// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: '/api/v1',
  env: 'dev',
  host: 'https://dev.api.futuri.education/api/v1',
  bucket: 'https://storage.googleapis.com/goreply-futuri-dev.appspot.com',
  firebase: {
    apiKey: "AIzaSyBZ2bM18ecQ9fSrCkwLMbN1cc2Tks7TAto",
    authDomain: "goreply-futuri-dev.firebaseapp.com",
    projectId: "goreply-futuri-dev",
    storageBucket: "goreply-futuri-dev.appspot.com",
    messagingSenderId: "274164852273",
    appId: "1:274164852273:web:2e4e875a6b40dc7f1fd710"
  },
  classroom: {
    CLIENT_ID: '274164852273-7aluhahuoc53bv3686f8blkuk7hm0fcg.apps.googleusercontent.com',
    API_KEY: 'AIzaSyAwDz_Z8Vbu0KQx_tiI01lW8UEhvPJ7nk0',
    SCOPES:
      'https://www.googleapis.com/auth/classroom.courses.readonly ' +
      'https://www.googleapis.com/auth/classroom.rosters.readonly ' +
      'https://www.googleapis.com/auth/classroom.profile.emails ',
    DISCOVERY_DOCS: [
      'https://classroom.googleapis.com/$discovery/rest',
    ]
  },
  futuri: {
    redirect_url: 'https://dev.futuri.education',
    domain: 'futuri.education'
  },
  docente: {
    redirect_url: 'https://dev.docente.futuri.education'
  },
  studente: {
    redirect_url: 'https://dev.studente.futuri.education'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
