import { ScuolaTag } from ".";
import { StatoGiudizio } from "./enum/StatoGiudizio";

export class Giudizio {
    id!: string;
    note!: string;
    scuole!: Array<ScuolaTag>;
    stato!: StatoGiudizio;
    studenteCognome!: string;
    studenteId!: string;
    studenteNome!: string;
}