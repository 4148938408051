import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/_service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        //ATTUALMENTE NON IN USO
        /* const cUser = this.authService.user;
        if (this.authService.isExpiredToken()) {
            return this.authService.refreshToken();
        }
        if (cUser) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        // this.router.navigate(['console/login'], { queryParams: { returnUrl: state.url }});
        this.authService.goToLogin(); */

        // GUARD PER PAGINA VERIFICA_MAIL
        var token = route.queryParams['token'];
        if (!!token && token) {
          return true;
        }

        this.authService.goToLogin()
        return false;
    }
}
