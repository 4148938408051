import { IscrizioneStudenteResponse, MateriaResponse } from ".";
import { Constants, Storager } from "../_utility";

export class RegistrazioneStudente {
    public id?: string;
    public codiceInvito?: string;
    public istituto?: string;
    public grado?: number;
    public sezione?: string;
    public existToken?: boolean;
    public step?: number;
    public routerLink?: Array<string>;
    public step2?: Step2Studente;
    public step3?: Step3Studente;
    public step4?: Step4Studente
    public step1?: IscrizioneStudenteResponse;
    private keyStorager: string;
    public token?: string;

    constructor() {
        this.keyStorager = Constants.Storager.REGISTRAZIONE_STUDENTE;
    }

    setStorager(storager: Storager): void {
        let obj: RegistrazioneStudente = this.getStorager(storager, false);
        if (!obj) {
            obj = new RegistrazioneStudente();
        }
        Object.assign(obj, this);
        storager.setElement(this.keyStorager, obj);
    }

    getStorager(storager: Storager, toAssign: boolean = true): RegistrazioneStudente {
        let registrazioneStudente: RegistrazioneStudente = storager.getElement(this.keyStorager);
        if (toAssign) {
            Object.assign(this, registrazioneStudente);
        }
        return registrazioneStudente;
    }

    removeStorager(storager: Storager): void {
        storager.removeElement(this.keyStorager);
    }
}

export interface Step2Studente {
    id?: string;
    dataNascita?: any;
    sesso?: boolean;
    nome?: string;
    cognome?: string;
    email?: string;
    ripetiEmail?: string;
    password?: string;
    ripetiPassword?: string;
    emailsGenitori?: Array<string>;
    consenso?: boolean;
    idToken?: string;
}

export interface Step3Studente {
    materie: Array<MateriaResponse>;
}

export interface Step4Studente {
    photoUrl: any;
}
