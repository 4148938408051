import { NumberSymbol } from "@angular/common";
import { ComuneResponse } from "./interface/response/comune-response";
import { IstitutoResponse } from "./interface/response/istituto-response";
import { MateriaResponse } from "./interface/response/materia-response";
import { PlessoResponse } from "./interface/response/plesso-response";

export class RegistrazioneDocente {
    id?: string;
    nome?: string;
    cognome?: string;
    email?: string;
    plessoName?: string;
    materie?: Array<string>;
    istituto?: string;
    token?: string;
    step?: number;
    routerLink?: Array<string>;
    step2?: Step2;
    step3?: Step3;
    step4?: Step4;
    step5?: Step5;

    constructor() {}
}

export interface Step2 {
    materie: Array<MateriaResponse>;
    comune: ComuneResponse;
    istituto: IstitutoResponse;
    plesso: PlessoResponse | string;
    consenso1: boolean;
    consenso2: boolean;
    consenso3: boolean;
}

export interface Step3 {
    password: string;
    repeatePassword: string;
}

export interface Step4 {
    ruolo: boolean;
    annoServizio: NumberSymbol;
    annoScolastico: number;
    titolo: any,
    specializzazione: any
}

export interface Step5 {
    photoUrl: any;
}