import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Paginator } from 'primeng/paginator';
import {
  TipologiaGruppiQuestionari,
  TipologiaQuestionariNonCognitivi,
} from '../../../_model';
import {
  DomandeQuestionarioNC,
  Gruppo,
} from '../../../_model/DomandeQuestionarioNC';
import { QuestionariService } from '../../../_service';
import { Constants, MyToastrService } from '../../../_utility';
import { SpinnerService } from '../../spinner/spinner.service';
import { SvolgiAnteprimaProvaService } from '../svolgi-anteprima-prova.service';

@Component({
  selector: 'app-svolgi-questionario',
  templateUrl: './svolgi-questionario.component.html',
  styleUrls: ['./svolgi-questionario.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SvolgiQuestionarioComponent implements OnInit {
  @ViewChild('myForm', { static: false }) myForm: any;
  @ViewChild('paginator', { static: false }) paginator!: Paginator;

  @Input() public id?: string;
  @Input() public readonly?: boolean;
  @Output() saved = new EventEmitter<boolean>();
  @Output() info = new EventEmitter<any>();

  public datasource?: DomandeQuestionarioNC;
  public type!: TipologiaQuestionariNonCognitivi;
  public tipologiaGruppi = TipologiaGruppiQuestionari;
  public validPage: { [key: string]: boolean } = {};
  public currentPage: number = 0;
  private previousPage: number = 0;
  public totalPage: number = 0;

  get gruppiPaginate(): Array<Gruppo> | null {
    return this.datasource?.gruppi
      ? [this.datasource?.gruppi[this.currentPage]]
      : null;
  }

  get validAllPage(): boolean {
    if (!this.datasource || !this.datasource?.gruppi) {
      return false;
    }
    if (
      !this.validPage ||
      Object.keys(this.validPage).length < this.datasource?.gruppi?.length
    ) {
      return false;
    }
    let validAllPage = true;
    for (let i = 0; i < this.datasource?.gruppi?.length; i++) {
      if (validAllPage) {
        let isValid = this.validPage[String(i)];
        if (i == this.currentPage && this.myForm.invalid) {
          validAllPage = false;
        } else if (i !== this.currentPage && !isValid) {
          validAllPage = false;
        }
      }
    }
    return validAllPage;
  }

  constructor(
    private service: QuestionariService,
    private spinner: SpinnerService,
    private toast: MyToastrService,
    private svolgi: SvolgiAnteprimaProvaService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.service.getPreviewQuestionariNonCognitivi(this.id!).subscribe({
      next: (res: DomandeQuestionarioNC) => {
        this.spinner.hide();
        this.info.emit({ durata: res.durata, gradi: res.gradi, progress: 0 });
        this.datasource = res;
        this.totalPage = res.gruppi!.length;
      },
      error: (error) => {
        this.saved.emit(false);
        this.spinner.hide();
        this.svolgi.hide();
      },
    });
  }

  checkLimit(domandaClick: any, gruppo: any) {
    console.log(domandaClick, gruppo);
    if (domandaClick.scelta.length > domandaClick.limit - 1) {
      this.toast.WARNING(
        Constants.TITLE_WARNING,
        `non puoi scegliere più di ${domandaClick.limit} opzioni.`
      );
      domandaClick.risposte.forEach((rip: any) => {
        if (!domandaClick.scelta.includes(rip.valore)) {
          rip.disabled = true;
        } else {
          rip.disabled = false;
        }
      });
    } else {
      domandaClick.risposte.forEach((rip: any) => {
        rip.disabled = false;
      });
    }
  }

  valueChange($event: any, domandaClick: any, then?: any, gruppo?: any) {
    // console.log(domandaClick, then, gruppo);
    this.validPage[this.currentPage] = this.myForm.valid;
    if (!gruppo?.domande || !domandaClick.principale) {
      return;
    }

    let idThen = then?.id;
    gruppo.domande.forEach((domanda: any, b: any, c: any) => {
      if (!domanda.principale) {
        if (domanda.id === idThen) {
          domanda.hidden = false;
          for (let o of domanda.risposte) {
            if (!!o.then) {
              domanda.principale = true;
            }
          }
        } else {
          domanda.hidden = true;
          delete domanda.scelta;
        }
      }
      if (domandaClick.id === domanda.id) {
        if (!then) {
          for (let o in c) {
            if (o > b) {
              gruppo.domande[o].principale = false;
              gruppo.domande[o].hidden = true;
              delete gruppo.domande[o].scelta;
            }
          }
        }
      }
    });
  }

  getDomande(domanda: any) {
    return { domandaId: domanda.id, risposta: domanda.scelta };
  }

  save() {
    let body: Array<any> = [];
    this.datasource?.gruppi!.forEach((gruppo: any) => {
      switch (gruppo.tipologia) {
        case TipologiaGruppiQuestionari.tipologia2:
          gruppo.domande.forEach((domanda: any) => {
            if (!domanda.hidden) {
              if (!!domanda.scelta) {
                body.push(this.getDomande(domanda));
              }
            }
          });
          break;
        case TipologiaGruppiQuestionari.tipologia1:
          gruppo.gruppi.forEach((gruppo2: any) => {
            gruppo2.domande.forEach((domanda: any) => {
              if (!domanda.hidden) {
                if (!!domanda.scelta) {
                  body.push(this.getDomande(domanda));
                }
              }
            });
          });
          break;
        default:
          break;
      }
    });
    console.log(body);

    this.spinner.show();
    this.service.saveQuestionarioNonCognitivo(this.id!, body).subscribe({
      next: (res) => {
        this.spinner.hide();
        this.toast.SUCCESS(
          Constants.TITLE_SUCCESS,
          'questionari.svolgi.msg_success'
        );
        this.saved.emit(true);
      },
      error: (err) => {
        this.spinner.hide();
      },
    });
  }

  paginate(event: any) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    if (this.currentPage == event.page) {
      return;
    } else if (event.page > this.currentPage && this.myForm.invalid) {
      this.paginator.changePage(this.currentPage);
      this.toast.WARNING(
        Constants.TITLE_WARNING,
        'questionari.svolgi.msg_required'
      );
      return;
    }
    this.previousPage = this.currentPage;
    this.currentPage = event.page;
    this.validPage[this.previousPage] = this.myForm.valid;
    const percentage: number = ((this.currentPage + 1) / this.totalPage) * 100;
    this.info.emit({
      durata: this.datasource!.durata,
      gradi: this.datasource!.gradi,
      progress: percentage,
    });
  }
}
